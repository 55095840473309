.App {
    text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.global-footer {
    font-size: 36px;
    font-weight: bold;
}

.app-thanks-name {
    text-decoration: underline;
    font-style: italic;
    font-size: 20px;
    font-weight: bold;
    color: #ea1212;
}


