
.map-container {
    min-height: 480px;
    min-width: 1334px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.map-container__map-row {
    display: flex;
    flex-direction: row;
}

.action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
}

.action__button {
    width: 30%;
}

.action_color_content {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding-top: 12px;
}

.guide-top {
    font-size: 30px;
    font-weight: 900;
    font-style: italic;
    color: red;
}

.map-tips {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: flex-start;
    padding-top: 20px;
}

.map-tips__boss {
    /*width: 40%;*/
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: medium;
    font-weight: 400;
    color: coral;
}

.map-tips__chest {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: medium;
    font-weight: 400;
    margin-left: 44px;
    padding-top: 6px;
    padding-bottom: 6px;
    background: beige;
}

.map-tips__chest__sub {
    padding-bottom: 6px;
}

.map-tips__path {
    text-decoration: line-through;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: medium;
    font-weight: 400;
    padding-left: 20px;
}

.map-tips__path__doing {
    text-decoration: line-through;
    font-size: 30px;
    font-style: italic;
}


.map-tips__tag {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: medium;
    font-weight: 400;
    padding-left: 20px;
}

.map-tips__tag__title {
    font-size: 28px;
    font-weight: bold;
    color: crimson;
}

.confirm_text {
    font-size: 20px;
    font-weight: 900;
    color: red;
}



