
.content {
    width: 66px;
    height: 24px;
    border: #dbe3eb42 1px solid;
}

.content__text {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
}

.content-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 66px;
    height: 24px;
    border: #dbe3eb42 1px solid;
}

.content-icon__text {
    height: 100%;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
}

.content-icon__dir {
    font-size: 20px;
    color: #ea1212;
    padding-right: 2px;
}


.tile-icon-container {
    width: 22px;
    height: 22px;
    overflow: hidden; /* 超出容器的部分将被裁剪 */
    position: relative; /* 父容器需相对定位 */
}

.tile-icon-container__img {
    width: 100%;
    height: 100%;
    object-fit: scale-down; /* 图片在容器中居中显示并裁剪多余部分 */
}

