
.links-collection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
    font-size: 26px;
    font-style: italic;
}

.links-collection-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}
